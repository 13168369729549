import { notify } from 'Components/common/notify';

import { getPostRequest } from 'Utils/fetchUtils';
import { error, log } from 'Utils/logUtils';
import { getApiBaseUrl } from 'Utils/urlUtils';

/**
 * Subscribes to the given email
 * @param email
 * @param tourId
 * @param cityName
 * @param alertType
 */

export const sendAlertData = ({ email, tourId, cityName, alertType }: any) => {
	const request = getPostRequest({ email, tourId, cityName, alertType });

	return fetch(`${getApiBaseUrl()}/api/v1/alert/log-alert-details`, request)
		.then(() => {
			log('Subscription successful');
		})
		.catch(err => {
			error(err);
			notify.showNetworkError(err);
		});
};
