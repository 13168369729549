import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import Conditional from 'Components/common/conditional';
import LH2 from 'Components/common/localizedTags/localizedHeading2';
import LSpan from 'Components/common/localizedTags/localizedSpan';

import { GreenTickSvg } from 'Assets/svg/productPage';

import { getDomainConfig } from 'Utils/stateUtils';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';

const Wrapper = styled.div`
	margin-top: 1.5rem;
	border: 1px solid ${colors.GREY.E2};
	border-radius: 1rem;
	background: ${colors.WHITE};
`;

const Header = styled.div`
	padding: 1rem 1rem 0.625rem 1rem;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	svg {
		height: 0.8125rem;
		width: 1.5rem;
		margin: 0;
	}
`;

const Title = styled.div`
	.why-headout-title {
		font-size: 1.125rem;
		color: ${colors.GREY['44']};
		font-family: ${fonts.HALYARD.DISPLAY};
		font-weight: 500;
		line-height: 1.4375rem;
	}
`;

const ContentWrapper = styled.div`
	border-top: 1px solid ${colors.GREY.E2};
	padding: 1rem;
`;

const RowWrapper = styled.div`
	display: flex;
	align-items: flex-start;

	svg {
		margin-top: 0.3125rem;
		width: 0.875rem;
		height: 0.875rem;
	}

	:last-child > div {
		margin-bottom: 0;
	}
`;

const Content = styled.div`
	flex: 1;
	margin: 0rem 0.875rem 0.625rem 0.875rem;

	.pitch-content-heading {
		font-size: 0.875rem;
		color: ${colors.GREY['66']};
		font-family: ${fonts.HALYARD.TEXT};
		line-height: 0.9375rem;
	}
`;

type HeadoutPitchProps = {
	showHeadoutPitch?: boolean;
	isSwitchedToOriginalLang?: boolean;
};

const HeadoutPitch = ({ showHeadoutPitch }: HeadoutPitchProps) => (
	<Conditional if={showHeadoutPitch}>
		<Wrapper data-qa-marker='headout-pitch'>
			<Header>
				<Title>
					<LH2 className='why-headout-title'>
						{strings.GMHS_WHY_HEADOUT}
					</LH2>
				</Title>
			</Header>
			<ContentWrapper>
				{[
					strings.PPHP_PITCH_1,
					strings.PPHP_PITCH_2,
					strings.PPHP_PITCH_3,
					strings.PPHP_PITCH_4,
					strings.CMN_LIVE_CHAT,
				].map((content, i) => (
					<RowWrapper key={i}>
						<GreenTickSvg
							className='green-tick'
							aria-hidden='true'
						/>
						<Content>
							<LSpan className='pitch-content-heading'>
								{content}
							</LSpan>
						</Content>
					</RowWrapper>
				))}
			</ContentWrapper>
		</Wrapper>
	</Conditional>
);

const mapStateToProps = (state: any, ownProps: any) => ({
	showHeadoutPitch: getDomainConfig(state)?.['showHeadoutPitch'],
	isSwitchedToOriginalLang: ownProps.isSwitchedToOriginalLang,
});

export default withRouter(connect(mapStateToProps, null)(HeadoutPitch));
