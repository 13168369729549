import React from 'react';
import ReactDOM from 'react-dom';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import colors from 'Static/typography/colors';

const CoreModalContainer = styled.div<{
	animateOpening?: boolean;
	animateClosing?: boolean;
	close?: boolean;
}>`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	opacity: 1;
	z-index: 999;
	transition: all 0.2s ease;

	background: ${({ animateOpening, animateClosing, close }) => {
		if (close && animateClosing) return 'rgba(0, 0, 0, 0)';
		if (animateOpening) return 'rgba(0, 0, 0, 0.5)';
		return 'rgba(0, 0, 0, 0.5)';
	}};

	&.right-end-modal {
		justify-content: flex-end;
		align-items: flex-start;
	}

	&.core-modal-hide {
		opacity: 0;
		height: 0;
		top: 50%;
	}

	&.calendar-modal-container {
		align-items: flex-start;
	}

	.calendar-content-wrapper {
		min-width: auto;
		min-height: auto;
		box-shadow: none;
		background: none;
		margin-top: 10.5rem;
	}

	@media (max-width: 768px) {
		.product-alert-modal-content {
			width: 100% !important;
			min-height: 200px !important;
		}

		&.product-alert-modal-control {
			align-items: flex-end !important;
		}
	}
`;

const CoreModalContent = styled.div`
	overflow: auto;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 80%;
	min-height: 50%;
	background: ${colors.WHITE};
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	align-items: center;
	justify-content: center;

	&.display-block {
		display: block;
	}

	&.fixed-width {
		min-width: auto;
		width: 23.4375rem;
	}

	&.large-fixed-width {
		min-width: auto;
		width: 37.5rem;
	}

	&.full-width {
		min-width: auto;
		width: 100vw;
	}

	&.full-width-height {
		min-width: auto;
		width: 100vw;
		height: 100vh;
	}
`;

type OwnProps = {
	containerClassName?: string;
	containerHideClassName?: string;
	contentClassName?: string;
	open?: boolean;
	onRequestClose?: (...args: any[]) => any;
	animateOpening?: boolean;
	animateClosing?: boolean;
	close?: boolean;
	allowPageScroll?: boolean;
	children: React.ReactNode;
};

type Props = OwnProps;

/**
 * This is a core modal component
 * The show/hide animation styles can be overridden via a combination of
 * "containerClassName" and "containerHideClassName"
 */
class Modal extends React.Component<Props> {
	static defaultProps: OwnProps = {
		containerClassName: '',
		containerHideClassName: '',
		contentClassName: '',
		animateOpening: false,
		animateClosing: false,
		close: false,
		children: null,
	};

	componentDidMount() {
		this.toggleBodyOverflow(this.props.open);
		window.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillUnmount() {
		this.toggleBodyOverflow(false);
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	bodyHasOverflowHidden() {
		return window.document.body.className.includes('overflow-hidden');
	}

	handleKeyDown = (event: KeyboardEvent) => {
		const { open, onRequestClose } = this.props;

		if (event.key === 'Escape' && open && onRequestClose) {
			onRequestClose();
		}
	};

	// Prevents scrolling background
	toggleBodyOverflow = (isOpen: any) => {
		if (this.props.allowPageScroll) return;
		if (isOpen && !this.bodyHasOverflowHidden()) {
			window.document.body.className +=
				window.document.body.className === ''
					? 'overflow-hidden'
					: ' overflow-hidden';
		} else if (!isOpen) {
			window.document.body.className =
				window.document.body.className.replace(
					/(?:\soverflow-hidden|overflow-hidden\s|^overflow-hidden)/,
					'',
				);
		}
	};
	render() {
		const {
			open,
			containerClassName,
			containerHideClassName,
			contentClassName,
			onRequestClose,
			children,
			animateOpening,
			animateClosing,
			close,
		} = this.props;
		return ReactDOM.createPortal(
			open ? (
				<CoreModalContainer
					className={` ${containerClassName} ${
						!open ? `core-modal-hide ${containerHideClassName}` : ''
					}`}
					onClick={e => {
						e.preventDefault();
						if (onRequestClose) {
							onRequestClose(e);
						}
					}}
					animateOpening={animateOpening}
					animateClosing={animateClosing}
					close={close}
				>
					<CoreModalContent
						className={` ${contentClassName}`}
						onClick={e => {
							// need both as one was not enough
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						{children}
					</CoreModalContent>
				</CoreModalContainer>
			) : null,
			// @ts-expect-error TS(2345): Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
			document.getElementById('modal-root'),
		);
	}
}

export default Modal;
